var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialogWrapper",
      attrs: {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        title: _vm.title + "详情",
        visible: _vm.dialogVisible,
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.detailType != "batchFillUp"
        ? _c(
            "div",
            [
              _c("h1", { staticClass: "lineHeight60" }, [_vm._v("订单信息")]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.orderList,
                    "row-class-name": _vm.tableRowClass,
                  },
                },
                _vm._l(_vm.orderListLabel, function (item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: {
                      align: "center",
                      prop: item.prop,
                      label: item.label,
                      width: item.width,
                      formatter: item.formatter,
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("h1", { staticClass: "lineHeight60" }, [_vm._v("流水信息")]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.transFlowList,
                "row-class-name": _vm.tableRowClassName,
              },
            },
            [
              (
                _vm.mynumber == "1"
                  ? _vm.detailType != "batchFillUp"
                  : _vm.mynumber == "1"
              )
                ? _c("el-table-column", {
                    attrs: {
                      label: "",
                      prop: "name",
                      width: "",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.$index == 0
                                ? _c("span", [_vm._v("我方")])
                                : _vm._e(),
                              scope.$index == 1
                                ? _c("span", [_vm._v("对方")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      899228861
                    ),
                  })
                : _vm._e(),
              _vm._l(_vm.transFlowListLabel, function (item) {
                return _c("el-table-column", {
                  key: item.prop,
                  attrs: {
                    align: "center",
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                    formatter: item.formatter,
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "80px",
            disabled: _vm.detailType == "detail",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("searchModule.Handling_method") } },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.title == "重复支付" ? "退款" : _vm.title)),
              ]),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("searchModule.remarks") } },
            [
              _c("el-input", {
                attrs: { type: "textarea", maxlength: "500" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.detailType == "detail"
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("关 闭")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detailType == "payRepeatList"
                            ? "退款"
                            : "确认补单"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }