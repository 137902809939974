<template>
  <!--详情弹窗-->
  <!--
    title： 标题
    transFlowList：流水记录集合
    transFlowListLabel：流水tableLabel集合
    orderList：订单记录集合
    orderListLabel：订单tableLabel集合
    detailType: 详情类型
  -->
  <el-dialog class="dialogWrapper" :close-on-click-modal="false" :close-on-press-escape="false" :title="title + '详情'" :visible.sync="dialogVisible" width="80%">
    <div  v-if="detailType != 'batchFillUp'">
      <h1 class="lineHeight60">订单信息</h1>
      <el-table  :data="orderList" style="width: 100%;" :row-class-name="tableRowClass">
        <el-table-column align='center' :prop="item.prop" :label="item.label" :width="item.width" v-for="item in orderListLabel" :key="item.prop" :formatter="item.formatter"></el-table-column>
      </el-table>
    </div>
    <div >
      <h1 class="lineHeight60">流水信息</h1>
      <el-table  :data="transFlowList" style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column label="" prop="name" width="" align = "center" v-if="(mynumber == '1') ? (detailType != 'batchFillUp'): (mynumber == '1') ">
          <template slot-scope="scope">
            <!-- {{scope.$index}} -->
             <!-- :class="{red: scope.$index == 0}" -->
            <span v-if="scope.$index == 0">我方</span>
            <span v-if="scope.$index == 1">对方</span>
          </template>
        </el-table-column>
        <el-table-column align='center' :prop="item.prop" :label="item.label" :width="item.width" v-for="item in transFlowListLabel" :key="item.prop" :formatter="item.formatter"
        ></el-table-column>
      </el-table>
    </div>
    <el-form ref="form" :model="form" label-width="80px" :disabled="detailType =='detail'">
      <el-form-item :label="$t('searchModule.Handling_method')">
        <span>{{ title == "重复支付" ? "退款" : title }}</span>
      </el-form-item>
      <el-form-item :label="$t('searchModule.remarks')">
        <el-input type="textarea" maxlength="500" v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align:center">
      <div v-if="detailType =='detail'">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </div>
      <div v-else>
        <el-button type="primary" @click="submit">{{ detailType == "payRepeatList" ? "退款" : "确认补单" }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: "billDiffDetail",
    props: ['colorRed', 'mynumber', 'transFlowList', 'transFlowListLabel', 'orderList', 'orderListLabel', 'title', 'detailType', 'transFlowListIds', 'refundData', 'billType'],
    data() {
      return {
        dialogVisible: false,
        form: {
          remark: ''
        },
        colorType: '',
        detailRefundData: {},
        detailTransFlowList: [],
        detailTransFlowListLabel: [],
        detailOrderList: [],
        detailOrderListLabel: [],
        detailTransFlowListIds: []
      }
    },
    watch: {
      refundData: {
        handler(val) {
          this.detailRefundData = val
        },
        deep: true
      },
      transFlowList: {
        handler(val) {
          this.detailTransFlowList = val
        },
        deep: true
      },
      transFlowListLabel: {
        handler(val) {
          this.detailTransFlowListLabel = val
        },
        deep: true
      },
      orderList: {
        handler(val) {
          this.detailOrderList = val
        },
        deep: true
      },
      orderListLabel: {
        handler(val) {
          this.detailOrderListLabel = val
        },
        deep: true
      },
      transFlowListIds: {
        handler(val) {
          this.detailTransFlowListIds = val
        },
        deep: true
      }
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if (this.colorRed == 2) {
          if (rowIndex === 0) {
            if (this.detailType == 'batchFillUp') {
              return ''
            } else {
              return 'warning-row';
            }
          } else if (rowIndex === 1) {
            return '';
          }
          return '';
        } else {
          if (rowIndex === 0) {
            return '';
          } else if (rowIndex === 1) {
            return 'warning-row';
          }
          return '';
        }
      },
      tableRowClass({row, rowIndex}) {
        if (this.colorRed == 2) {
          if (rowIndex >= 0) {
            return 'warning-row';
          } else {
            return '';
          }
        }
      },
      dialogVisibleShow(remark) {
        this.dialogVisible = true;
        if (remark) {
          this.form.remark = remark;
        } else {
          this.form.remark = '';
        }
      },
      submit() {
        if (this.detailType == 'billDiffList' || this.detailType == 'batchFillUp') {
          this.submitMakeUp();
        } else if (this.detailType == 'payRepeatList') {
          this.submitRefund()
        }
      },
      submitMakeUp() {
        this.dialogVisible = false;
        this.$axios.post('/acb/2.0/pendingOrder/submitMakeUp', {
          data: {
            tradeCode: this.detailTransFlowListIds.toString(),
            billType: this.billType,
            remark: this.form.remark
          }
        }).then(res => {
          if (res.state == 0) {
            this.$message({
              message: res.desc,
              type: 'success'
            });
            this.$EventBus.$emit('billDifListSearchData')
          }
        })
      },
      submitRefund() {
        this.dialogVisible = false;
        this.$axios.post('/acb/2.0/repeatPayOrder/submitRefund', {
          data: Object.assign(this.detailRefundData, this.form)
        }).then(res => {
          if (res.state == 0) {
            this.$EventBus.$emit('payRepeatListSearchData')
            this.$message({
              message: res.value,
              type: 'success'
            });
          }
        })
      }
    },
    mounted() {
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .lineHeight60
    line-height: 60px;
  .dialogWrapper>>>
    .el-dialog__body
      padding-top 0
    .el-dialog__header
      background: #19202c
    .el-dialog__title
      color: #fff
    .el-dialog__header
      padding 10px
    .el-dialog__headerbtn
      top: 15px
      color: #fff
    .el-dialog__headerbtn .el-dialog__close
      color: #fff
</style>
<style>
  .el-table .warning-row {
    /* background: oldlace; */
    color: red;
  }
  .el-table .success-row {
    /* background: #f0f9eb; */
    color: black;
  }
</style>
